// process.env should be written out like this, otherwise values won't be replaced by Webpack

// Pendejos 3100-3199
// Team Work 3301-3399
// Team Actions 3200-3299
// Team Falcon 3400-3499
// Team Paradigm 35xx

const REMOTE_APP_SETTINGS_URL =
  process.env.REACT_APP_REMOTE_APP_SETTINGS_URL || 'http://localhost:3100';

const REMOTE_PAGE_SETTINGS_URL =
  process.env.REACT_APP_REMOTE_PAGE_SETTINGS_URL || 'http://localhost:3101';

const REMOTE_ACTIONS_OVERVIEW_URL =
  process.env.REACT_APP_REMOTE_ACTIONS_OVERVIEW_URL || 'http://localhost:3200';

const REMOTE_ACTION_STEP_DETAIL_VIEW_URL =
  process.env.REACT_APP_REMOTE_ACTION_STEP_DETAIL_VIEW_URL ||
  'http://localhost:3201';

const REMOTE_ACTION_CANVAS_URL =
  process.env.REACT_APP_REMOTE_ACTION_CANVAS_URL || 'http://localhost:3202';

const REMOTE_TRANSLATIONS_URL =
  process.env.REACT_APP_REMOTE_TRANSLATIONS_URL || 'http://localhost:3131';

const REMOTE_STYLE_EDITOR_URL =
  process.env.REACT_APP_REMOTE_STYLE_EDITOR_URL || 'http://localhost:3301';

const REMOTE_OBJECT_TREE_URL =
  process.env.REACT_APP_REMOTE_OBJECT_TREE_URL || 'http://localhost:3302';

const REMOTE_ROLES_OVERVIEW_URL =
  process.env.REACT_APP_REMOTE_ROLES_OVERVIEW_URL || 'http://localhost:3401';

const REMOTE_PUBLIC_FILES_URL =
  process.env.REACT_APP_REMOTE_PUBLIC_FILES_URL || 'http://localhost:3402';

const REMOTE_CONFIGURATIONS_URL =
  process.env.REACT_APP_REMOTE_CONFIGURATIONS_URL || 'http://localhost:3403';

const REMOTE_BLOCK_STORE_MODAL_URL =
  process.env.REACT_APP_REMOTE_BLOCK_STORE_MODAL_URL || 'http://localhost:3405';

const REMOTE_LOGS_URL =
  process.env.REACT_APP_REMOTE_LOGS_URL || 'http://localhost:3404';

function urlConfig({ key, value }) {
  return localStorage.getItem(key) ? localStorage.getItem(key) : value;
}

window.remotes = {
  appSettingsUrl: urlConfig({
    key: 'REMOTE_APP_SETTINGS_URL',
    value: REMOTE_APP_SETTINGS_URL,
  }),
  pageSettingsUrl: urlConfig({
    key: 'REMOTE_PAGE_SETTINGS_URL',
    value: REMOTE_PAGE_SETTINGS_URL,
  }),
  actionsOverviewUrl: urlConfig({
    key: 'REMOTE_ACTIONS_OVERVIEW_URL',
    value: REMOTE_ACTIONS_OVERVIEW_URL,
  }),
  actionStepDetailViewUrl: urlConfig({
    key: 'REMOTE_ACTION_STEP_DETAIL_VIEW_URL',
    value: REMOTE_ACTION_STEP_DETAIL_VIEW_URL,
  }),
  actionCanvasUrl: urlConfig({
    key: 'REMOTE_ACTION_CANVAS_URL',
    value: REMOTE_ACTION_CANVAS_URL,
  }),
  blockStoreModalUrl: urlConfig({
    key: 'REMOTE_BLOCK_STORE_MODAL_URL',
    value: REMOTE_BLOCK_STORE_MODAL_URL,
  }),
  translationsUrl: urlConfig({
    key: 'REMOTE_TRANSLATIONS_URL',
    value: REMOTE_TRANSLATIONS_URL,
  }),
  styleEditorUrl: urlConfig({
    key: 'REMOTE_STYLE_EDITOR_URL',
    value: REMOTE_STYLE_EDITOR_URL,
  }),
  objectTreeUrl: urlConfig({
    key: 'REMOTE_OBJECT_TREE_URL',
    value: REMOTE_OBJECT_TREE_URL,
  }),
  rolesOverviewUrl: urlConfig({
    key: 'REMOTE_ROLES_OVERVIEW_URL',
    value: REMOTE_ROLES_OVERVIEW_URL,
  }),
  publicFilesOverviewUrl: urlConfig({
    key: 'REMOTE_PUBLIC_FILES_URL',
    value: REMOTE_PUBLIC_FILES_URL,
  }),
  configurationsOverviewUrl: urlConfig({
    key: 'REMOTE_CONFIGURATIONS_URL',
    value: REMOTE_CONFIGURATIONS_URL,
  }),
  logsOverviewUrl: urlConfig({
    key: 'REMOTE_LOGS_URL',
    value: REMOTE_LOGS_URL,
  }),
};
